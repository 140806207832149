<template>
  <section class="outContainer">
    <!-- 搜索 -->
    <div class="search">
        <el-input class="each" v-model="searchInfo.param.title" placeholder="请输入标题"></el-input>
        <el-input class="each" v-model="searchInfo.param.source" placeholder="请输入来源"></el-input>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">查询</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close"  @click="handleSearch('clear')">清空搜索条件</el-button>
        <el-button size="mini" @click="handleAdd(null)">新增</el-button>
    </div>
    <div class="tableContainer">
        <!-- 表格数据 -->
        <el-table
          :data="tableList"
          style="width: 100%"
          height="100%"
          class="table"
          fit
        >
          <el-table-column align='center' label="标题" prop="title">
          </el-table-column>
          <el-table-column align='center' label="来源" prop="source">
          </el-table-column>
          <el-table-column align='center' label="是否置顶" prop="topNum">
            <template slot-scope="scope">
              {{ scope.row.topNum || scope.row.topNum === 0 ? '是' : '否'}}
            </template>
          </el-table-column>
          <el-table-column align='center' label="创建时间" prop="createTimeStr">
          </el-table-column>
          <el-table-column align='center' label="操作" fixed="right" width="200">
            <template slot-scope="scope">
              <p style="margin-bottom: 5px;">
                <el-button size="mini" @click="handleUnTop(scope.row)" v-if="scope.row.topNum || scope.row.topNum === 0">取消置顶</el-button>
                <el-button size="mini" @click="handleTop(scope.row)" v-else>置顶</el-button>
                <!-- <el-button size="mini" @click="handleDetail(scope.row)">详情</el-button> -->
              </p>
              <p>
                <el-button size="mini" @click="handleAdd(scope.row)">编辑</el-button>
                <el-button size="mini" @click="handleDel(scope.row)">删除</el-button>
              </p>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <PaginationVue v-bind:child-msg="searchInfo" @callFather="callFather"></PaginationVue>
        <!-- 新增、编辑 -->
        <el-drawer 
          :title="row ? '编辑' : '新增'"
          :visible.sync="addDrawer"
          size="50%"
          style="height: 100%;">
          <add v-if="addDrawer" @close="handleClose"  ref='newForm' :row="row"></add>
        </el-drawer>
        <!-- 详情 -->
        <el-drawer 
          title="详情"
          :visible.sync="detailDrawer"
          size="50%">
          <detail v-if="detailDrawer" @close="handleClose"  ref='newForm' :row="row"></detail>
        </el-drawer>
    </div>
  </section>
</template>

<script>
import { getInformationList,deleteInformation,topInformation,unTopInformation } from '@/api/public.js';
import PaginationVue from '@/components/Pagination.vue';
import add from './add.vue';
import detail from './detail.vue';
import { timeChange } from '@/api/publicFun.js';
export default {
    data(){
        return {
            tableList:[], // 表格数据
            searchInfo:{ // 搜索条件
                pageNo:1,
                pageSize:10,
                total:0,
                param:{
                    title:'',
                    source:"",
                }
            },
            addDrawer:false, // 控制侧边新增弹框是否显示
            detailDrawer:false, // 控制侧边详情弹框是否显示
            row:null, // 当前操作数据
        }
    },
    mounted(){
        this.init();
    },
    components:{
        PaginationVue,
        add,
        detail,
    },
    methods:{
        // 获取表格数据
        async init(){
          await getInformationList(this.searchInfo).then(res=>{
            if(res.success){
              this.tableList = res.data.list;
              this.searchInfo.total = res.data.total;
              this.tableList.forEach(element => {
                let eachTime = timeChange(element.createTime);
                element.createTimeStr = `${eachTime.year}-${eachTime.month}-${eachTime.day}  ${eachTime.hour}:${eachTime.minutes}:${eachTime.seconds}`;
              });
            }else{
              this.tableList = [];
              this.searchInfo.total = 0;
            }
          })
        },
        // 搜索事件
        handleSearch(type){
            if(type == 'clear'){
                this.searchInfo.param = {
                    title:'',
                    source:"",
                }
            }
            this.searchInfo.pageNo = 1;
            this.init();
        },
        // 置顶
        handleTop(row){
          this.$confirm('确认置顶该资讯吗？').then(async () => {
            row.topNum = 1;
            await topInformation({param:row.id}).then(res=>{
              if(res.success){
                this.$message({
                  message:'操作成功',
                  type:'success'
                })
              }else{
                this.$message({
                  message:res.msg,
                  type:'fail'
                })
              }
              this.init();
            })
          })
        },
        // 取消置顶
        handleUnTop(row){
          this.$confirm('确认取消置顶该资讯吗？').then(async () => {
            row.topNum = 1;
            await unTopInformation({param:row.id}).then(res=>{
              if(res.success){
                this.$message({
                  message:'操作成功',
                  type:'success'
                })
              }else{
                this.$message({
                  message:res.msg,
                  type:'fail'
                })
              }
              this.init();
            })
          })
        },
        // 打开新增、编辑
        handleAdd(row){
          this.row = row;
          this.addDrawer = true
        },
        // 打开详情
        handleDetail(row){
          this.row = row;
          this.detailDrawer = true;
        },
        // 关闭侧边弹框
        handleClose(){
          this.addDrawer = false;
          this.detailDrawer = false;
          this.init();
        },
        // 删除
        handleDel(row){
          this.$confirm('确认删除该资讯吗？').then(async () => {
            await deleteInformation({param:row.id}).then(res=>{
              if(res.success){
                this.$message({
                  message:'删除成功',
                  type:'success'
                })
              }else{
                this.$message({
                  message:res.msg,
                  type:'fail'
                })
              }
              this.init();
            })
          })
        },
        // 分页
        callFather(parm){
          this.searchInfo.pageNo = parm.currentPage;
          this.init();
        },
    }
}
</script>

<style lang="scss">
  @import "@/static/default";
/* 内容 */
.container{
    background-color: #fff !important;
    padding:0 32px;
    .toolbar{
      background: #fff;
      width:calc(100% + 64px);
      padding:16px 32px 26px 0;
      margin-left:-32px;
    }
}
</style>