<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
        <el-form-item label="标题" prop="title">
          <el-input placeholder="请输入标题" v-model="form.title" ></el-input>
        </el-form-item>
        <el-form-item label="来源" prop="source">
          <el-input placeholder="请输入来源" v-model="form.source"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <quill-editor
            class='editor'
            v-model="form.content"
            ref="myQuillEditor"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @change="onEditorChange($event)"
            @ready="onEditorReady($event)">
          </quill-editor>
          <input v-show="false" type="file" id="upload" name="avatar" accept="image/png, image/jpeg" @change="chooseImg"/>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="handleSubmit('form')" :plain='true'>立即提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
  
  <script>
  // 富文本组件引用，富文本组件的文字大小等配置，是在node_modules中quill模块的各个文件中去手动更改的
  import { quillEditor } from 'vue-quill-editor';
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import 'quill/dist/quill.bubble.css';
  import Quill from "quill";
  import ImageResize from "quill-image-resize-module"; // 引用
  import { ImageDrop } from "quill-image-drop-module";
  Quill.register("modules/imageDrop", ImageDrop);
  Quill.register("modules/imageResize", ImageResize); // 注册
  Quill.register('formats/font', Quill.import('attributors/style/size'));
  const titleConfig = [
  { Choice: '.ql-insertMetric', title: '跳转配置' },
  { Choice: '.ql-bold', title: '加粗' },
  { Choice: '.ql-italic', title: '斜体' },
  { Choice: '.ql-underline', title: '下划线' },
  { Choice: '.ql-header', title: '段落格式' },
  { Choice: '.ql-strike', title: '删除线' },
  { Choice: '.ql-blockquote', title: '块引用' },
  { Choice: '.ql-code', title: '插入代码' },
  { Choice: '.ql-code-block', title: '插入代码段' },
  { Choice: '.ql-font', title: '字体' },
  { Choice: '.ql-size', title: '字体大小' },
  { Choice: '.ql-list[value="ordered"]', title: '编号列表' },
  { Choice: '.ql-list[value="bullet"]', title: '项目列表' },
  { Choice: '.ql-direction', title: '文本方向' },
  { Choice: '.ql-header[value="1"]', title: 'h1' },
  { Choice: '.ql-header[value="2"]', title: 'h2' },
  { Choice: '.ql-align', title: '对齐方式' },
  { Choice: '.ql-color', title: '字体颜色' },
  { Choice: '.ql-background', title: '背景颜色' },
  { Choice: '.ql-image', title: '图像' },
  { Choice: '.ql-video', title: '视频' },
  { Choice: '.ql-link', title: '添加链接' },
  { Choice: '.ql-formula', title: '插入公式' },
  { Choice: '.ql-clean', title: '清除字体格式' },
  { Choice: '.ql-script[value="sub"]', title: '下标' },
  { Choice: '.ql-script[value="super"]', title: '上标' },
  { Choice: '.ql-indent[value="-1"]', title: '向左缩进' },
  { Choice: '.ql-indent[value="+1"]', title: '向右缩进' },
  { Choice: '.ql-header .ql-picker-label', title: '标题大小' },
  { Choice: '.ql-header .ql-picker-item[data-value="1"]', title: '标题一' },
  { Choice: '.ql-header .ql-picker-item[data-value="2"]', title: '标题二' },
  { Choice: '.ql-header .ql-picker-item[data-value="3"]', title: '标题三' },
  { Choice: '.ql-header .ql-picker-item[data-value="4"]', title: '标题四' },
  { Choice: '.ql-header .ql-picker-item[data-value="5"]', title: '标题五' },
  { Choice: '.ql-header .ql-picker-item[data-value="6"]', title: '标题六' },
  { Choice: '.ql-header .ql-picker-item:last-child', title: '标准' },
  { Choice: '.ql-size .ql-picker-item[data-value="small"]', title: '小号' },
  { Choice: '.ql-size .ql-picker-item[data-value="large"]', title: '大号' },
  { Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: '超大号' },
  { Choice: '.ql-size .ql-picker-item:nth-child(2)', title: '标准' },
  { Choice: '.ql-align .ql-picker-item:first-child', title: '居左对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="center"]', title: '居中对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="right"]', title: '居右对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="justify"]', title: '两端对齐' }
  ];
  // 请求接口api
  import { createInformation,updateInformation } from '@/api/public.js';
  import OSS from 'ali-oss';
  export default {
    props:['row'], // 父组件携带参数
    data() {
      return {
        client: {
          Embed:"",
        },
        uploadUrl: 'https://nuoyanyun-app-pic.oss-cn-hangzhou.aliyuncs.com', // 这里填写OSS提供的外链地址
        form:{ // 表单数据
          title: "", // 标题
          source: "", // 来源
          content: null, // 内容
        },
        rules: { // 表单验证
          title:[
            { required: true, message: '标题不得为空', trigger: 'blur',}
          ],
          source:[
            { required:true,message:'来源不得为空',trigger:"blur"}
          ],
          content:[
            { required:true,message:'内容不得为空',trigger:"blur"}
          ],
        },
        editorOption: { // 富文本配置
          placeholder: '请输入',
          theme: "snow",
          modules: {
            toolbar:{
              container: [
                ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                ['blockquote', 'code-block'], // 引用  代码块
                [{ header: 1 }, { header: 2 }], // 1、2 级标题
                [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                [{ indent: '-1' }, { indent: '+1' }], // 缩进
                [{ size: ['12px', '14px', '16px', '18px', '20px','22px','24px','28px','32px','36px'] }], // 字体大小
                [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
                [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                [{ align: [] }], // 对齐方式
                ['clean'], // 清除文本格式
                ['image'] // 链接、图片，需要视频的可以加上video
              ],
              handlers: {   // 此处是图片上传时候需要使用到的
                'image': function (value) {
                  if (value) {  // 点击图片
                    document.querySelector('#upload').click()
                  }
                }
              }
            },
            imageDrop: true,   // 图片拖拽
            imageResize: {     // 图片放大缩小
              displayStyles: {
                backgroundColor: "black",
                border: "none",
                color: "white"
              },
              modules: ["Resize", "DisplaySize", "Toolbar"]
            }
          }
        },
        imageUrl:null,
      };
    },
  
    components: {
      quillEditor
    },

    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
    },
  
    mounted() {
      this.form = this.row ? {...this.row} : this.form;
      for (let item of titleConfig) {
        let tip = document.querySelector('.quill-editor ' + item.Choice)
        if (!tip) continue
        tip.setAttribute('title', item.title)
      }
    },

    created() {},
  
    methods: {
      // 关闭弹窗
      close(form){
         this.$emit('close');
      },
      // 选择上传图片
      async chooseImg(event){
        const file = event.target.files[0];
        console.log(file,9987)
        if (!file) return;
        // 配置你的OSS参数
        const client = new OSS({
          region: 'oss-cn-hangzhou', //创建的时候，bucket所在的区域，华北2->oss-cn-beijing ；其他的可以去百度
          accessKeyId: 'LTAI5tFhYLbrCmDsQAqneEtY',// 阿里云控制台创建的AccessKey
          accessKeySecret: 'eKVnXwjMI8gBuMfSmE4S6RcL3PFBGh', //阿里云控制台创建的AccessSecret
          bucket: 'nuoyanyun-app-pic', //创建的bucket的名称
        });
        try {
          // 上传文件到OSS
          const result = await client.put(`newsImgs/${file.name}`, file);
          this.imageUrl = result.url;
          // 获取光标所在位置
          let quill = this.$refs.myQuillEditor.quill;
          console.log(this.$refs.myQuillEditor,999)
          let length = quill.getSelection().index
          // 插入图片
          quill.insertEmbed(length, 'image', this.imageUrl)  // imageUrl:图片地址
          // 调整光标到最后
          quill.setSelection(length + 1)
        } catch (error) {
          this.imageUrl = null;
          console.error(error);
        }
      },
      // 失去焦点事件
      onEditorBlur(quill) {
        console.log('editor blur!', quill)
      },
      // 获得焦点事件
      onEditorFocus(quill) {
        console.log('editor focus!', quill)
      },
      // 准备富文本编辑器
      onEditorReady(quill) {
        console.log('editor ready!', quill)
      },
      // 富文本内容改变事件，只需要这一个方法就够了
      onEditorChange({ quill, html, text }) {
        console.log('editor change!',html);
        this.form.content = html;
      },
      // 新增
      async add(){
        await createInformation({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
              this.init();
            }, 1000);
          }else{
            this.$message({
              message: "提交失败，请重试",
              type: "fail",
            });
          }
        })
      },
      // 编辑
      async edit(){
        await updateInformation({param:this.form}).then((res) => {
          if (res.success) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("close");
              this.init();
            }, 1000);
          }else{
            this.$message({
              message: "提交失败，请重试",
              type: "fail",
            });
          }
        })
      },
      // 提交
      handleSubmit(form) {
         this.$refs["form"].validate(valid => {
            if (valid) {
                this.$confirm('确认提交吗？', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(()=>{
                  if(this.row){
                    this.edit();
                  }else{
                    this.add();
                  }
                })
            }else{
              return false;
            }
        });
      },
    }
  };
  </script>
  <style lang="scss">
  /* 首行缩进 */
  .ql-indent-1{
    padding-left: 0px !important;
    text-indent: 2em;
  }
  .ql-indent-2{
    padding-left: 0px !important;
    text-indent: 4em;
  }
  .ql-indent-3{
    padding-left: 0px !important;
    text-indent: 6em;
  }
  .ql-indent-4{
    padding-left: 0px !important;
    text-indent: 8em;
  }
  .ql-indent-5{
    padding-left: 0px !important;
    text-indent: 10em;
  }
  .ql-indent-6{
    padding-left: 0px !important;
    text-indent: 12em;
  }
  .ql-indent-7{
    padding-left: 0px !important;
    text-indent: 14em;
  }
  .ql-indent-8{
    padding-left: 0px !important;
    text-indent: 16em;
  }
  .editor {
        margin: auto;
        line-height: normal !important;
        height: 650px;
        display: flex;
        flex-direction: column;
        width: 375px;
  }
  .ql-snow .ql-tooltip[data-mode="link"]::before {
      content: "请输入链接地址:";
  }
  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
      border-right: 0px;
      content: "保存";
      padding-right: 0px;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow{
    flex: 1;
    overflow: auto;
  }
    

    .ql-snow .ql-picker.ql-header .ql-picker-label::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item::before {
        content: "文本";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
        content: "标题1";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
        content: "标题2";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
        content: "标题3";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
        content: "标题4";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
        content: "标题5";
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
        content: "标题6";
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item::before {
      content: "标准字体";
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
      content: "衬线字体";
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
      content: "等宽字体";
    }
    
    
    
    .ql-snow .ql-picker.ql-size .ql-picker-label::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item::before {
        content: "16px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
        content: "12px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
        content: "14px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
        content: "16px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
        content: "18px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
        content: "20px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="22px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
        content: "22px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
        content: "24px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="28px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
        content: "28px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
        content: "32px";
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
        content: "36px";
    }

    /* 实际字体大小 */
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
        font-size: 12px;
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
        font-size: 14px;
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
        font-size: 16px;
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
        font-size: 18px;
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
        font-size: 20px;
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
        font-size: 22px;
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
        font-size: 24px;
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
        font-size: 28px;
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
        font-size: 32px;
    }
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
        font-size: 36px;
    }
    .ql-size-12{
      font-size: 12px;
    }.ql-size-14{
      font-size: 14px;
    }.ql-size-16{
      font-size: 16px;
    }.ql-size-18{
      font-size: 18px;
    }.ql-size-20{
      font-size: 20px;
    }.ql-size-22{
      font-size: 22px;
    }.ql-size-24{
      font-size: 24px;
    }.ql-size-28{
      font-size: 28px;
    }.ql-size-32{
      font-size:32px;
    }.ql-size-36{
      font-size: 36px;
    }
  /* 防止地图自动完成的对象被遮挡 */
  .tangram-suggestion {
      z-index: 9999 !important;
    }
   .el-drawer{
     overflow:auto !important;
   }
   v-deep .el-form-item__content{
    display: flex;
   }
   .map{
    border: 1px solid #dcdfe6;
    background: #fff;
    border-radius: 4px;
    width: 80px;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  