<template>
  <div>
    <h2>{{ this.detailInfo.title }}</h2>
    <p class="source">
        <span>
            {{ detailInfo.source }}
        </span>
    </p>
    <div class="content">
        {{ detailInfo.content }}
    </div>
  </div>
</template>

<script>
import {getInformationDetail} from '@/api/public.js';
export default {
  props:['row'], // 父组件携带参数
  data() {
    return {
        detailInfo:{}
    }
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 关闭弹窗
    close(form){
        this.$emit('close');
    },
    async init(){
        await getInformationDetail({param:this.row.id}).then(res=>{
            if(res.success){
                this.detailInfo = res.data;
            }else{
                this.$message({
                    message:res.msg,
                    type:'fail'
                })
                this.$emit('close');
            }
        })
    },
  },
};
</script>
<style scoped lang="scss">
.source{
    padding: 10px 0;
}
.content{
    padding: 10px;
    text-indent: 2em;
    font-size: 14px;
    line-height: 30px;
    text-align:justify;
}
</style>
